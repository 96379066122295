<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="titles blue-text">Announcement</div>
      <v-card  class="pb-4 rounded-5 shadow-0">
        <v-btn
          small
          @click="gotoMail"
          absolute
          top
          right
          style="top: -50px; margin-right: -1%"
        >
          <v-icon small>mdi-backburger</v-icon>Back
        </v-btn>
        <v-card-text class="pb-0">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <label for="">{{type == 'sms*' ? 'Title*' : 'Subject*'}}</label>
              <v-text-field
                v-model="messageForm.title"
                outlined
                dense
                hide-details="hide-details"
                validate-on-blur
                required
                :rules="[
                  (v) =>
                    !!v ||
                    (type == 'sms' ? 'Title' : 'Subject') + ' is required',
                ]"
              ></v-text-field>
            </v-col>
            <div class="ml-4" style="margin-top: -10px">
              <!-- <span class="overline">Placeholders</span> -->
              <!-- <v-tooltip top max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                  </v-btn>
                </template> -->
              <!-- <span class="text-center">
                Placeholder is used to add dynamic data in you message.
                Placerholder will be replaced with actual data representing each
                of them. Please click to copy and paste it on your message
              </span> -->
              <!-- </v-tooltip> -->
              <!-- <template v-for="item in placeholders">
                <v-chip
                  :key="item.id"
                  class="mr-2 pa-4"
                  @click="copyPlaceholder(item)"
                >
                  {{ item.name }}
                  <v-icon right small>mdi-content-copy</v-icon>
                </v-chip>
              </template> -->
            </div>
            <v-col cols="12" sm="12" md="12">
              <label>Message</label>
              <v-textarea
                v-if="type == 'announcement'"
                v-model="messageForm.message"
                outlined
                required
                :rules="[(v) => !!v || 'Message is required']"
                rows="3"
                dense
              ></v-textarea>
              <text-editor
                v-else
                @complete="setEmailContent"
                :message="emailEditContent"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div class="titles mt-8 blue-text">Configuration</div>
      <v-card class="rounded-5 shadow-0">
        <v-card-text class="pb-0">
          <v-row>
            <!-- <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="messageForm.message_type"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                label="Schedule Type*"
                item-text="name"
                :readonly="messageForm.id != null"
                item-value="value"
                :items="[
                  { name: 'Scheduled', value: 's' },
                  { name: 'Automated', value: 'a' },
                ]"
                required
                :rules="[(v) => !!v || 'Type is required']"
              ></v-select>
            </v-col> -->
            <v-col cols="12" sm="4" md="4">
              <label for="">Type*</label>
              <v-select
                v-model="messageForm.marketing_type"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                item-value="value"
                :items="[{ name: 'General Announcement', value: 'a' }]"
                @change="search"
                required
                :rules="[(v) => !!v || 'Type is required']"
                class="q-autocomplete shadow-0"
                dense
                hide-details="hide-details"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <label for="">Scheduled Date*</label>
              <date-field
                v-model="messageForm.date"
                :rules="[(v) => !!v || 'Schedule date is required']"
                :hide-details="true"
                class-name="q-text-field shadow-0"
                dense
                label=""
                :backFill="
                  checkBackfillPermission($modules.marketing[type].slug)
                "
              ></date-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <label for="">Hour*</label>
              <v-text-field
                v-model="messageForm.time"
                type="time"
                :min="minTime"
                outlined
                required
                :rules="[(v) => !!v || 'Schedule time is required']"
                class="q-text-field shadow-0"
                dense
                hide-details="hide-details"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-row class="mt-3">
        <v-spacer></v-spacer>
        <v-btn @click="gotoMail" class="ma-2 white--text blue-color" text
          >Close</v-btn
        >
        <v-btn
          color="darken-1"
          class="ma-2 white--text teal-color"
          text
          @click="saveMarketingMessage"
          >Save</v-btn
        >
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import moment from "moment";
import TextEditor from "@/components/Marketing/TextEditor";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      messageForm: {
        venue_service_ids: [],
        country_ids: [],
        membership_ids: [],
        product_type_ids: [],
        customer_type_ids: [],
        message_type: "s",
      },
      menu: false,
      currentPage: 0,
      pageSize: 10,
      items: [],
      names: [],
      name: null,
      customer_type_id: null,
      country: null,
      customerTypes: [],
      phones: [],
      phone: null,
      emails: [],
      email: null,
      isLoading: false,
      searchDatas: [],
      searchFlag: false,
      AgeRange: ["All", "18-25", "26-30", "31-35"],
      Age: [],
      toAge: null,
      fromAge: null,
      page: 1,
      perPage: null,
      totalPages: 1,
      userModel: { userID: null, type: "details" },
      selected: [],
      currentPageIds: [],
      valid: false,
      rules: [],
      placeholders: [],
      type: "announcement",
      emailEditContent: "",
      currentDay: moment().format("YYYY-MM-DD"),
    };
  },
  components: {
    TextEditor,
  },
  mounted() {
    if (typeof this.$route.params.type != "undefined") {
      this.type = this.$route.params.type;
      this.messageForm.type = this.type;
    }
    if (typeof this.$route.params.id != "undefined") {
      this.messageForm.id = this.$route.params.id;
      this.getMessageDetails();
    }
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getMemberships.status == false) {
      this.$store.dispatch("loadMemberships");
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    this.getAutomatedRules();
    this.getPlaceholders();
    this.getCustomerType();
  },
  watch: {
    page() {
      this.search();
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
  },
  computed: {
    ...mapGetters({
      checkReadPermission: "checkReadPermission",
    }),
    minTime() {
      let marker = "00:00";
      if (
        this.messageForm.date == null ||
        moment(this.messageForm.date, "YYYY-MM-DD").isSame()
      ) {
        marker = moment().format("HH:mm");
      }
      return marker;
    },
    getFormatDate() {
      if (this.messageForm.date) {
        return moment(String(this.messageForm.date)).format("Do MMM YYYY");
      }
      return null;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    memberships() {
      return this.$store.getters.getMemberships.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  methods: {
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.messageForm[field].length == this[type].length) {
          this.messageForm[field] = [];
        } else {
          if (type == "memberships") {
            this.messageForm[field] = this[type].map((x) => x.membership_id);
          } else {
            this.messageForm[field] = this[type].map((x) => x.id);
          }
        }
        this.search();
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (this.messageForm[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.messageForm[field].length > 0 &&
        this.messageForm[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },

    copyPlaceholder(data) {
      const el = document.createElement("textarea");
      el.value = data.placeholder;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showInfo(
        "Placeholder for " + data.name + " copied to clipboard",
        2000
      );
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
        .post("venues/marketing/customers?" + type + "=" + val)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterData() {
      this.page = 1;
      this.search();
    },
    ageOkClick() {
      if (this.fromAge != null && this.toAge != null) {
        this.messageForm.from_age = this.fromAge;
        this.messageForm.to_age = this.toAge;
        let customAgeRange = this.fromAge + "-" + this.toAge;
        this.AgeRange.push(customAgeRange);
        this.messageForm.age = customAgeRange;
        this.toAge = null;
        this.fromAge = null;
        this.$refs.ageRef.blur();
        this.search();
      }
    },
    ageChange() {
      if (this.messageForm.age != "All") {
        let data = this.messageForm.age.split("-");
        this.messageForm.from_age = data[0];
        this.messageForm.to_age = data[1];
        this.search();
      } else {
        this.allAge();
      }
    },
    allAge() {
      delete this.messageForm.from_age;
      delete this.messageForm.to_age;
      delete this.messageForm.age;
      this.search();
    },
    search() {
      if (this.messageForm.name == "All") {
        this.messageForm.name = "";
      }
      if (this.messageForm.email == "All") {
        this.messageForm.email = "";
      }
      if (this.messageForm.mobile == "All") {
        this.messageForm.mobile = "";
      }
      if (this.messageForm.gender == "All") {
        this.messageForm.gender = "";
      }
      if (this.messageForm.country == "All") {
        this.messageForm.country = [];
      }

      if (this.messageForm.customer_type == "All") {
        this.messageForm.customer_type = [];
      }

      this.messageForm.page = this.page;
      this.messageForm.per_page = this.perPage;

      this.$http
        .post("venues/marketing/customers", this.messageForm)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.searchDatas = response.data.data;
            this.totalPages = response.data.total_pages;
            this.selected = response.data.data.map((data) => data.id);
            if (
              typeof this.messageForm.excluded_customer_ids != "undefined" &&
              this.messageForm.excluded_customer_ids.length > 0
            ) {
              this.selected = this.selected.filter(
                (i) => !this.messageForm.excluded_customer_ids.includes(i)
              );
            }
            this.currentPageIds = response.data.data.map((data) => data.id);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    getPlaceholders() {
      this.$http
        .get("venues/marketing/messages/placeholders?type=" + this.type)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.placeholders = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getAutomatedRules() {
      let rule = "";
      if (this.messageForm.rule_id != null) {
        rule = "&rule_id=" + this.messageForm.rule_id;
      }
      this.$http
        .get("venues/marketing/messages/rules?type=" + this.type + rule)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.rules = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    customerSelection() {
      let data = this.currentPageIds.filter((i) => !this.selected.includes(i));
      if (typeof this.messageForm.excluded_customer_ids == "undefined") {
        this.messageForm.excluded_customer_ids = [];
      }
      this.messageForm.excluded_customer_ids.push(...data);
      this.messageForm.excluded_customer_ids = this.messageForm.excluded_customer_ids.filter(
        (id) => !this.selected.includes(id)
      );
      this.messageForm.excluded_customer_ids = [
        ...new Set(this.messageForm.excluded_customer_ids),
      ];
    },
    saveMarketingMessage() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      if (this.messageForm.message_type == "s") {
        this.messageForm.schedule_date = moment(
          this.messageForm.date + " " + this.messageForm.time,
          "YYYY-MM-DD HH:mm"
        ).format("YYYY-MM-DD HH:mm:ss");
        delete this.messageForm.rule_id;
      } else {
        this.messageForm.schedule_date = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      var config = {
        method: "post",
        url:
          "venues/marketing" +
          (this.messageForm.id != null ? "/" + this.messageForm.id : ""),
        data: this.messageForm,
      };
      this.$http(config)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.showSuccess("Added successfully");
            this.$router.push({
              name: "AnnouncementDetails",
              params: { type: this.type },
            });
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getMessageDetails() {
      this.showLoader("Loading");
      this.$http
        .get("venues/marketing/" + this.messageForm.id)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.messageForm.title = data.title;
            this.messageForm.message = data.message;
            if (this.type == "email") {
              this.emailEditContent = data.message;
            }
            this.messageForm.message_type = data.message_type;
            this.messageForm.marketing_type = data.marketing_type;
            this.$set(
              this.messageForm,
              "date",
              moment(data.schedule_date, "YYYY-MM-DD HH:mm:ss").format(
                "YYYY-MM-DD"
              )
            );
            this.$set(
              this.messageForm,
              "time",
              moment(data.schedule_date, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
            );

            if (data.name_filter != null) {
              this.names.unshift(data.name_filter);
              this.names.unshift("All");
              this.messageForm.name = data.name_filter;
            }
            if (data.mobile_filter != null) {
              this.phones.unshift(data.mobile_filter);
              this.phones.unshift("All");
              this.messageForm.mobile = data.mobile_filter;
            }
            if (data.email_filter != null) {
              this.emails.unshift(data.email_filter);
              this.emails.unshift("All");
              this.messageForm.email = data.email_filter;
            }

            this.messageForm.membership_type = data.membership_type;
            this.messageForm.venue_service_ids = data.venue_service_ids.map(
              (item) => parseInt(item.venue_service_id)
            );
            this.messageForm.membership_ids = data.membership_ids.map(
              (item) => item.membership_id
            );
            this.messageForm.country_ids = data.country_ids.map((item) =>
              parseInt(item.country_id)
            );
            if (data.customer_type_ids) {
              this.messageForm.customer_type_ids = data.customer_type_ids.map(
                (item) => parseInt(item.customer_type_ids)
              );
            }

            this.messageForm.product_type_ids = data.product_type_ids.map(
              (item) => parseInt(item.product_type_id)
            );

            if (data.to_age != null && data.from_age != null) {
              let age = data.from_age + "-" + data.to_age;
              if (!this.AgeRange.includes(age)) this.AgeRange.push(age);
              this.messageForm.age = age;
            }

            if (data.customer_type_id != "" || data.customer_type_id != null)
              this.messageForm.customer_type_id = data.customer_type_id;

            if (data.excluded_customer_ids != null) {
              this.messageForm.excluded_customer_ids =
                data.excluded_customer_ids;
            }
            this.messageForm.rule_id = data.rule_id;
            this.search();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getCustomerType() {
      this.$http
        .get("general-types/customer-type")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.customerTypes = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    setEmailContent(content) {
      this.messageForm.message = content;
    },
    gotoMail() {
      this.$router.push({
        name: "AnnouncementDetails",
        params: { type: this.type },
      });
    },
  },
};
</script>

<style></style>
